import React, { useState, useCallback, useEffect, useRef, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useOutsideHandling } from '../Hooks/CustomHooks';
import { useMedicalConditions } from '../Hooks/MedicalConditions';
import Block from '../block';
import { MedicalConditionPagination } from './Common/MedicalConditionPagination';
const contentful = require('contentful');
import { RichText } from '../richTextOptions';

const client = contentful.createClient({
     space: process.env.CONTENTFUL_SPACE_ID,
     accessToken: process.env.CONTENTFUL_ACCESS_TOKEN
});

const ConditionPopup = ({ condition }) => {
     const { setActiveCondition, handleClickBodySystemOption, handleClickCategory, handleClickOutsidePopup } = useContext(AllConditionsContext);

     return (
          <>
               <div className="condition-overlay" onClick={handleClickOutsidePopup} />
               <div className="condition-details">
                    <button className="close" onClick={() => setActiveCondition('')}>
                         X
                    </button>
                    <p className="condition-name">{condition.conditionName.charAt(0).toUpperCase() + condition.conditionName.slice(1)}</p>
                    <div className="more-info">
                         <p>
                              <strong>Examples: </strong>
                              {condition?.description?.description && (
                                   <>
                                        <span
                                             dangerouslySetInnerHTML={{
                                                  __html: condition.description.description.replace(/\r?\n/g, '<br />') // Preview is content, not preview is content['en-US']
                                             }}
                                        ></span>
                                   </>
                              )}
                         </p>
                         <p>
                              <strong>Body System: </strong>
                              {condition?.bodySystem && (
                                   <button className="cursor-pointer" onClick={() => handleClickBodySystemOption(condition.bodySystem)}>
                                        {condition.bodySystem}
                                   </button>
                              )}
                         </p>
                         <p>
                              <i></i>
                              <strong>Condition type: </strong>
                              {condition?.categories?.map?.((category, index) => (
                                   <React.Fragment key={category}>
                                        <button className="cursor-pointer" onClick={() => handleClickCategory(category)}>
                                             {category}
                                        </button>
                                        {index !== condition.categories.length - 1 && ', '}
                                   </React.Fragment>
                              ))}
                         </p>
                    </div>
               </div>
          </>
     );
};

const ConditionList = ({ conditionList }) => {
     const { activeCondition, setActiveCondition } = useContext(AllConditionsContext);

     return (
          <>
               {conditionList &&
                    conditionList.map((el) => (
                         <React.Fragment key={el.contentful_id}>
                              {el?.conditionName && (
                                   <li>
                                        <button onClick={() => setActiveCondition(el.contentful_id)}>{el.conditionName.charAt(0).toUpperCase() + el.conditionName.slice(1)}</button>
                                        {activeCondition === el.contentful_id && <ConditionPopup condition={el} />}
                                   </li>
                              )}
                         </React.Fragment>
                    ))}
          </>
     );
};

const AllConditionsContext = React.createContext();

const SectionAllMedicalConditions = (props) => {
     const dispatch = useDispatch();
     const {
          conditions,
          categoryIcons,
          bodySystemOptions,
          accidentText,
          illnessText,
          wellnessText,
          bodySystemFilterText,
          bodyHereditaryFilterText,
          bodyCongenitalFilterText,
          bottomNote
     } = props;
     const [conditionsByCategories, setConditionsByCategories] = useState({});
     const { filterOpened, mobileFilterOpened, activePage, activeCondition, activeMedicalConditionTab, bodySystemFilter } = useSelector(
          (state) => state.app.sectionAllMedicalConditions
     );
     const categories = ['Accident', 'Illness', 'Wellness'];
     let conditionResults = []; // Conditions after filtering
     let textBelowFilter;

     // Handle getting text below filter
     //When selecting Congenital and Hereditary from the "filter by body system" drop down, it does not list any conditions and there is no information or direction on where the member should go next.
     if (bodySystemFilter) {
          const validFilters = ['Congenital', 'Hereditary'];
          const flagBodySystemFilter = validFilters.includes(bodySystemFilter);

          if (flagBodySystemFilter) {
               textBelowFilter =
                    bodySystemFilter === 'Hereditary' ? bodyHereditaryFilterText : bodyCongenitalFilterText;
          } else {
               textBelowFilter = bodySystemFilterText;
          }
     } else {
          switch (activeMedicalConditionTab) {
               case 'Accident':
                    textBelowFilter = accidentText;
                    break;
               case 'Illness':
                    textBelowFilter = illnessText;
                    break;
               case 'Wellness':
                    textBelowFilter = wellnessText;
                    break;
               default:
          }
     }

     if (categories.includes(activeMedicalConditionTab) && conditionsByCategories[activeMedicalConditionTab]) {
          conditionResults = conditionsByCategories[activeMedicalConditionTab];
     }

     if (activeMedicalConditionTab === '') {
          conditionResults = conditionsByCategories.All;
     }

     if (bodySystemFilter && bodySystemFilter !== 'All') {
          conditionResults = conditions;
          conditionResults = conditionResults?.filter?.((el) => el.bodySystem === bodySystemFilter);
     }

     let conditionsPerColumn = 18;
     if (conditionResults.length <= 18) {
          conditionsPerColumn = 6;
     } else if (conditionResults.length < 30) {
          conditionsPerColumn = Math.ceil(conditionResults.length / 3);
     }

     const conditionsPerPage = 3 * conditionsPerColumn;
     const totalPages = Math.ceil(conditionResults.length / conditionsPerPage);
     const offset = conditionsPerPage * (activePage - 1);
     conditionResults = conditionResults.sort((a, b) =>
          a.conditionName.toLowerCase() > b.conditionName.toLowerCase() ? 1 : b.conditionName.toLowerCase() > a.conditionName.toLowerCase() ? -1 : 0
     );
     const conditionsToDisplay = conditionResults.slice(offset, offset + conditionsPerPage); // Conditions to displayed on the page
     const firstColumnConditions = conditionsToDisplay.slice(0, conditionsPerColumn);
     let secondColumnConditions;
     let thirdColumnConditions;

     if (conditionsToDisplay.length > conditionsPerColumn) {
          secondColumnConditions = conditionsToDisplay.slice(conditionsPerColumn, conditionsPerColumn * 2);
     }

     if (conditionsToDisplay.length > conditionsPerColumn * 2) {
          thirdColumnConditions = conditionsToDisplay.slice(conditionsPerColumn * 2);
     }

     const toggleFilter = useCallback(() => {
          dispatch({ type: 'SET_FILTER_OPENED_SECTION_ALL_MEDICAL_CONDITIONS', payload: !filterOpened });
     }, [filterOpened]);

     const toggleMobileFilter = useCallback(() => {
          dispatch({ type: 'SET_MOBILE_FILTER_OPENED_SECTION_ALL_MEDICAL_CONDITIONS', payload: !mobileFilterOpened });
     }, [mobileFilterOpened]);

     const handleClickCategory = (category) => {
          dispatch({ type: 'HANDLE_CLICK_CATEGORY_SECTION_ALL_MEDICAL_CONDITIONS', payload: category });
     };

     const handleClickBodySystemOption = (bodySystem) => {
          dispatch({ type: 'HANDLE_CLICK_BODY_SYSTEM_OPTION_SECTION_ALL_MEDICAL_CONDITIONS', payload: bodySystem });
     };

     const setActiveCondition = (condition) => {
          dispatch({ type: 'SET_ACTIVE_CONDITION_SECTION_ALL_MEDICAL_CONDITIONS', payload: condition });
     };

     useEffect(() => {
          let newConditions = { All: [] };

          conditions?.forEach?.((condition) => {
               if (Array.isArray(condition?.categories)) {
                    if (condition.categories.includes('Accident') || condition.categories.includes('Illness') || condition.categories.includes('Wellness')) {
                         newConditions.All.push(condition);
                    }

                    condition.categories.forEach((category) => {
                         if (Array.isArray(newConditions[category])) {
                              newConditions[category].push(condition);
                         } else {
                              newConditions[category] = [condition];
                         }
                    });
               }
          });

          setConditionsByCategories(newConditions);
     }, [conditions]);

     const handleClickOutside = useCallback(() => {
          dispatch({ type: 'SET_FILTER_OPENED_SECTION_ALL_MEDICAL_CONDITIONS', payload: false });
     }, []);

     const handleClickOutsideMobileFilter = useCallback(() => {
          dispatch({ type: 'SET_MOBILE_FILTER_OPENED_SECTION_ALL_MEDICAL_CONDITIONS', payload: false });
     }, []);

     const handleClickOutsidePopup = useCallback(() => {
          dispatch({ type: 'SET_ACTIVE_CONDITION_SECTION_ALL_MEDICAL_CONDITIONS', payload: '' });
     }, []);

     const filterRef = useRef(null);
     const mobileFilterRef = useRef(null);
     useOutsideHandling(filterRef, handleClickOutside);
     useOutsideHandling(mobileFilterRef, handleClickOutsideMobileFilter);

     return (
          <section id="all-medical-conditions" className="all-medical-conditions">
               <div className="container">
                    <div className="tabs-and-filter-wrapper">
                         <div className="container">
                              <div className={`condition-tabs ${activeMedicalConditionTab.toLowerCase()}`}>
                                   {categories.map((category, index) => (
                                        <button key={category} className={activeMedicalConditionTab === category ? 'active' : ''} onClick={() => handleClickCategory(category)}>
                                             {categoryIcons[index] && <Block block={categoryIcons[index]} key={index} />}
                                        </button>
                                   ))}
                              </div>
                              <div className="condition-filter select-box" ref={filterRef}>
                                   <div className="select-text">
                                        <button onClick={toggleFilter}>{bodySystemFilter || 'Filter by body system'}</button>
                                        <div className={`arrow-down ${filterOpened ? 'rotate' : ''}`}></div>
                                   </div>
                                   {/* {
                            filterOpened && (
                                <ul>
                                    <li onClick={() => handleClickBodySystemOption('All')}>All</li>
                                    {
                                        Array.isArray(bodySystemOptions) && bodySystemOptions.map(option => (
                                            <li key={option} onClick={() => handleClickBodySystemOption(option)}>{option}</li>
                                        ))
                                    }
                                </ul>
                            )
                        } */}
                                   <div className="list-select-wrapper">
                                        <div className={`${filterOpened ? 'show' : ''}`}>
                                             <div className="list-select">
                                                  {/* <div className="select-item">
                                            <button tabIndex={filterOpened ? 0 : -1} onClick={() => handleClickBodySystemOption('All')}>All</button>
                                        </div> */}
                                                  {Array.isArray(bodySystemOptions) &&
                                                       bodySystemOptions.map((option) => (
                                                            <div key={option} className="select-item">
                                                                 <button tabIndex={filterOpened ? 0 : -1} onClick={() => handleClickBodySystemOption(option)}>
                                                                      {option}
                                                                 </button>
                                                            </div>
                                                       ))}
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
                    <div className="list-of-conditions-wrapper">
                         {/* {textBelowFilter && <p dangerouslySetInnerHTML={{ __html: textBelowFilter }}></p>} */}
                         {textBelowFilter && <RichText data={textBelowFilter} />}

                         <div className="condition-filter select-box" ref={mobileFilterRef}>
                              <div className="select-text">
                                   <button onClick={toggleMobileFilter}>{bodySystemFilter || 'Filter by body system'}</button>
                                   <div className={`arrow-down ${mobileFilterOpened ? 'rotate' : ''}`}></div>
                              </div>
                              {/* {
                            mobileFilterOpened && (
                                <ul>
                                    <li onClick={() => handleClickBodySystemOption('All')}>All</li>
                                    {
                                        Array.isArray(bodySystemOptions) && bodySystemOptions.map(option => (
                                            <li key={option} onClick={() => handleClickBodySystemOption(option)}>{option}</li>
                                        ))
                                    }
                                </ul>
                            )
                        } */}
                              <div className="list-select-wrapper">
                                   <div className={`${mobileFilterOpened ? 'show' : ''}`}>
                                        <div className="list-select">
                                             {/* <div className="select-item">
                                        <button onClick={() => handleClickBodySystemOption('All')}>All</button>
                                    </div> */}
                                             {Array.isArray(bodySystemOptions) &&
                                                  bodySystemOptions.map((option) => (
                                                       <div key={option} className="select-item">
                                                            <button onClick={() => handleClickBodySystemOption(option)}>{option}</button>
                                                       </div>
                                                  ))}
                                        </div>
                                   </div>
                              </div>
                         </div>
                         {Array.isArray(conditionsToDisplay) && conditionsToDisplay.length > 0 && (
                              <AllConditionsContext.Provider
                                   value={{ activeCondition, setActiveCondition, handleClickBodySystemOption, handleClickCategory, handleClickOutsidePopup }}
                              >
                                   <div id="condition-result-select" className="row">
                                        <ul className="col-lg-4 list-of-conditions">
                                             <ConditionList conditionList={firstColumnConditions} />
                                        </ul>
                                        <ul className="col-lg-4 list-of-conditions">
                                             <ConditionList conditionList={secondColumnConditions} />
                                        </ul>
                                        <ul className="col-lg-4 list-of-conditions">
                                             <ConditionList conditionList={thirdColumnConditions} />
                                        </ul>
                                   </div>
                                   {totalPages > 1 && (
                                        <MedicalConditionPagination
                                             activePage={activePage}
                                             setActivePage={(page) => dispatch({ type: 'SET_ACTIVE_PAGE_SECTION_ALL_MEDICAL_CONDITIONS', payload: page })}
                                             totalPages={totalPages}
                                        />
                                   )}

                                   {bottomNote && (
                                        <div className="condition-search-note">
                                             <RichText data={bottomNote} />
                                        </div>
                                   )}
                              </AllConditionsContext.Provider>
                         )}
                    </div>
               </div>
          </section>
     );
};

const ContentfulSectionAllMedicalConditions = (props) => {
     const isPreview = useSelector((state) => state.app.isPreview);
     const [conditions, setConditions] = useState([]);
     const graphQLConditions = useMedicalConditions();

     useEffect(() => {
          if (isPreview) {
               client
                    .getEntries({
                         content_type: 'medicalCondition',
                         order: 'fields.conditionName',
                         limit: 1000
                    })
                    .then((response) => {
                         if (response.items.length > 0) {
                              let newConditions = response.items.map((el) => ({ ...el.fields }));
                              setConditions(newConditions);
                         }
                    })
                    .catch(console.error);
          } else {
               let newConditions = graphQLConditions.map((el) => ({ ...el.node }));
               setConditions(newConditions);
          }
     }, []);

     return <SectionAllMedicalConditions {...props} conditions={conditions} />;
};

export default ContentfulSectionAllMedicalConditions;
